<template>
  <div>
    <template v-if="!open">
      <lazy-image
        v-if="video.length != 0 && video.thumbnail != ''"
        :src="'https://storage.terapivitrini.com/' + video.thumbnail"
        :alt="video.title"
        style="cursor: pointer; width: 100%"
        class="cover-image"
        :id="'img' + video.id"
      />
      <lazy-image
        v-if="video.thumbnail == '' || video.length == 0"
        :src="'https://storage.terapivitrini.com/storage/video_noimage.jpg'"
        style="cursor: pointer; width: 100%"
        class="cover-image"
        :id="'img' + video.id"
      />
    </template>
    <template v-else>
      <div id="video-path">
        <div style="width: 100%;" id="video" :class="'hls' + video.id"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ["video"],
  data() {
    return {
      open: false,
    };
  },
  watch: {
    video: function () {
      try {
        document.getElementById("video").remove();
        const para = document.createElement("div");
        para.id = "video";
        para.style = "width: 100%";
        para.class = "hls" + this.video.id;
        document.getElementById("video-path").appendChild(para);

        $("#video").aksVideoPlayer({
          file: this.video.files,
          poster: "https://storage.terapivitrini.com/" + this.video.thumbnail,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  methods: {
    vLoad() {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://terapivitrini.com/assets/js/aksVideoPlayer.min.js"
      );
      document.head.appendChild(recaptchaScript);

      let _this = this;
      document
        .getElementById("img" + this.video.id)
        .addEventListener("click", function () {
          _this.open = true;
          setTimeout(() => {
            $("#video").aksVideoPlayer({
              file: _this.video.files,
              poster:
                "https://storage.terapivitrini.com/" + _this.video.thumbnail,
            });
          }, 50);
        });
    },
  },
  mounted() {
    this.vLoad();
  },
};
</script>>
